<template>
  <div class="sm:py-4">
    <form
      class="p-4 sm:rounded sm:shadow sm:max-w-sm mx-auto bg-white"
      @submit.prevent="submitYo"
    >
      <h1 class="pb-4">Log In</h1>
      <a href="/social/login/google/login">
        <mdc-button
          :style="{ backgroundColor: '#4285F4' }"
          ripple
          raised
          class="w-full mb-4 relative"
        >
          <svg-google class="absolute bg-white top-0 left-0 w-9" />
          Log in with Google
        </mdc-button>
      </a>
      <!-- <a href="/social/login/facebook/login">
        <mdc-button
          :style="{ backgroundColor: '#3b5998' }"
          ripple
          raised
          class="w-full mb-4 relative"
        >
          <svg-facebook
            :style="{ width: '28px', left: '5px', bottom: '-3px' }"
            class="absolute text-white"
            path="social/facebook.svg"
          />
          Log in with Facebook
        </mdc-button>
      </a> -->
      <h3 class="py-4">Or log in with email</h3>
      <mdc-textfield
        v-model="email"
        label="Email"
        type="email"
        class="w-full"
        required
        autofocus
        autocomplete="username email"
      />
      <mdc-textfield
        v-model="password"
        label="Password"
        type="password"
        class="w-full"
        required
        autocomplete="current-password"
      />
      <mdc-checkbox v-model="remember" label="Remember me" class="my-4" />
      <mdc-button
        :disabled="$store.state.loading"
        class="w-full mb-8"
        type="submit"
        ripple
        raised
      >
        Log In
      </mdc-button>
      <router-link
        :to="email ? `/forgot-password/${email}` : '/forgot-password'"
        class="w-full my-4 mdc-button"
      >
        Forgot Your Password?
      </router-link>
    </form>
  </div>
</template>

<script>
import { alertDialog } from '@/utils/dialog';
import axios from '@/utils/xhr';
import MdcButton from '@/components/mdc/button.vue';
import MdcCheckbox from '@/components/mdc/checkbox.vue';
import MdcTextfield from '@/components/mdc/textfield.vue';
import SvgGoogle from '@/svg/social/google.svg';

export default {
  components: {
    MdcButton,
    MdcCheckbox,
    MdcTextfield,
    SvgGoogle,
  },

  data: () => ({
    email: '',
    password: '',
    remember: false,
  }),

  mounted() {
    if (this.$route.hash === '#_=_' || this.$store.state.isLoggedIn)
      this.$router.push('/email');
  },

  methods: {
    submitYo() {
      axios
        .post('/login', {
          email: this.email,
          password: this.password,
          remember: this.remember,
        })
        .then((response) => {
          this.$store.commit('user', response.data);
          this.$router.push('/email');
        })
        .catch(() => {
          alertDialog('Invalid Credentials. Please try again');
        });
    },
  },
};
</script>
